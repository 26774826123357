import { ApplicationData } from '@tymbe/schema/src/application.interface';
import { TyCompanyLogoFromBucket } from '../../../../components/blob/CompanyLogoFromBucket';
import { SideModal } from '@tymbe/ty-components/modals';
import { useState } from 'react';
import { TyCard } from '@tymbe/ty-components/layout';
import { ApplicationDetail } from './applicationDetail/ApplicationDetail';
import { Paragraph } from '@tymbe/ty-components/text';

type Props = {
  application?: ApplicationData;
  excuseId?: string | number;
};

export const CompanyColumn = ({ application, excuseId }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const company = application?.shift?.company;
  return (
    <TyCard
      onClick={() => {
        !isModalOpen && setModalOpen(true);
      }}
      className="!shadow-none px-2 py-3 [&>div]:flex [&>div]:items-center"
    >
      <TyCompanyLogoFromBucket
        src={company?.logo ?? undefined}
        className="mr-2 w-8 h-8"
      />
      <Paragraph variant="body2Rg" className="text-base-front-1">
        {application?.shift?.name}
      </Paragraph>
      <SideModal
        title="Detail směny"
        className="w-[520px]"
        isOpen={isModalOpen}
        onRequestClose={() => {
          setModalOpen(false);
        }}
      >
        {application?.id && (
          <ApplicationDetail
            applicationId={application!.id.toString()}
            activeExcuseId={excuseId?.toString()}
          />
        )}
      </SideModal>
    </TyCard>
  );
};
