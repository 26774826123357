import classNames from 'classnames';
import { ComponentProps } from 'react';
import { textStyles } from '../style/text-styles';
import { ButtonWithLoading } from './ButtonWithLoading';

export const PrimaryButton = ({
  className,
  ...props
}: ComponentProps<typeof ButtonWithLoading>) => (
  <ButtonWithLoading
    className={classNames(
      'bg-buttons-back-primary',
      'hover:border-buttons-back-primary-hover',
      'hover:bg-buttons-back-primary-hover',
      'transition',
      'ease-in',
      'relative',
      'rounded-3xl',
      'py-2 px-4',
      textStyles.cButtonMd,
      'text-buttons-front-primary-text',
      className
    )}
    type="submit"
    {...props}
  />
);
