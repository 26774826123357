import moment from 'moment';
import { ApplicationExcuseData } from '@tymbe/schema/src/application-excuse.interface';
import { AbsenceStateActions } from './AbsenceStateActions';
import { TyTableCell, TyTableRow } from '@tymbe/ty-components/table';
import { TySelect, TyTextArea } from '@tymbe/ty-components/inputs';
import { useFieldState } from 'informed';
import { AbsenceCategoryDisplay } from '../../../../utils/enums';
import { TyTooltip } from '@tymbe/ty-components/text/tooltip';
import { CompanyColumn } from './CompanyColumn';
import { PersonColumn } from './PersonColumn';
import { AbsenceStateBadgePill } from '../../../../components/BadgePills/AbsenceBadgePill';
import { AbsenceState } from '@tymbe/schema/enums';
import { FilesFromBucketViewer } from '../../../../components/blob/FilesFromBucketViewer';
import classNames from 'classnames';
import { Paragraph } from '@tymbe/ty-components/text';
import { ExcuseRevertButton } from './ExcuseRevertButton';

type AbsenceRowProps = {
  excuse: ApplicationExcuseData;
};

export const categoryOptions = Object.entries(AbsenceCategoryDisplay).map(
  ([key, value]) => ({ label: value, value: key })
);

export const AbsenceRow = ({ excuse }: AbsenceRowProps) => {
  const excuseStateRowName = `excuses[${excuse.id}].state`;
  const { value: state } = useFieldState(excuseStateRowName);
  const excuseIsClarification = excuse.application?.excuses?.find(
    (e) => e.state === AbsenceState.CLARIFICATION_NEEDED
  );

  const cols = [
    <PersonColumn person={excuse.application?.person} />,
    <CompanyColumn application={excuse.application} excuseId={excuse.id} />,
    moment(excuse.application?.shift?.start_time).format('DD.MM.YYYY, HH:mm'),
    <div className="flex items-center gap-1">
      {moment(excuse.updated_at).format('DD.MM.YYYY, HH:mm')}
      {excuseIsClarification && (
        <AbsenceStateBadgePill state={AbsenceState.CLARIFICATION_NEEDED} />
      )}
    </div>,
    // Enable changing category to excuses that have selected state
    // Otherwise just display the category
    state ? (
      <TySelect
        name={`excuses[${excuse.id}].category`}
        options={categoryOptions}
        initialValue={categoryOptions.find((c) => c.value === excuse.category)}
      />
    ) : (
      AbsenceCategoryDisplay[excuse.category]
    ),
    ,
    <TyTooltip overlay={excuse.reason ?? '-'} className="flex max-w-[400px]">
      <Paragraph variant="captionRg" className={`truncate`}>
        {excuse.reason ?? '-'}
      </Paragraph>
    </TyTooltip>,
    <FilesFromBucketViewer
      key={'filesViewer' + excuse.id}
      files={excuse.files.map((f) => f.file)}
      max={3}
    />,
    excuse.state && excuse.state !== AbsenceState.PROCESSING ? (
      <AbsenceStateBadgePill state={excuse.state} significant />
    ) : (
      <AbsenceStateActions name={excuseStateRowName} />
    ),
    ...(excuse.reviewed_at
      ? [moment(excuse.reviewed_at).format('DD.MM.YYYY, HH:mm')]
      : []),
    ...(excuse.state !== AbsenceState.PROCESSING
      ? [<ExcuseRevertButton excuse={excuse} />]
      : []),
  ];

  const rowBg = state ? 'bg-special-back-selected-1' : '';
  const showInput =
    state === AbsenceState.REJECTED ||
    state === AbsenceState.CLARIFICATION_NEEDED;
  const showNote = excuse.admin_note !== null;

  return (
    <>
      <TyTableRow className={rowBg} key={excuse.id}>
        {cols.map((col, idx) => (
          <TyTableCell
            key={idx}
            className={classNames(
              'border-x-0',
              'border-b-0',
              [0, 1].includes(idx) ? '!py-0 !px-0' : ''
            )}
          >
            {col}
          </TyTableCell>
        ))}
      </TyTableRow>
      {(showInput || showNote) && (
        <TyTableRow className={`${rowBg}`} key={`${excuse.id}-input`}>
          {/* Span input through last 3 columns */}
          <TyTableCell
            colSpan={cols.length - 3}
            className="border-x-0 border-t-0 !pt-0 !pb-3"
            key={`${excuse.id}-input-col`}
          />
          <TyTableCell
            colSpan={3}
            className="border-x-0 border-t-0 !pt-0 !pb-3"
            key={`${excuse.id}-input-col-2`}
          >
            {showInput ? (
              <TyTextArea
                name={`excuses[${excuse.id}].admin_note`}
                placeholder="Poznámka pro Tymbera (nepovinné)"
                rows={1}
              />
            ) : (
              <TyTooltip
                overlay={excuse.admin_note ?? '-'}
                className="flex max-w-[400px] justify-end float-right pr-6"
              >
                <Paragraph
                  variant="captionRg"
                  className="text-base-front-3 truncate"
                >
                  {excuse.admin_note}
                </Paragraph>
              </TyTooltip>
            )}
          </TyTableCell>
        </TyTableRow>
      )}
    </>
  );
};
