import { AbsenceCategory, AbsenceState } from '@tymbe/schema/enums';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import feathersClient from '../../../apiClient';
import { AbsenceRow } from './components/AbsenceRow';
import { CheckmarkIcon } from '../../../components/icons';
import { ApplicationExcuseData } from '@tymbe/schema/src/application-excuse.interface';
import {
  TyTable,
  TyTableRow,
  TyTableFooter,
  TyTablePagination,
  TyTableSortHeader,
  NoDataTableRow,
} from '@tymbe/ty-components/table';
import { PrimaryButton } from '@tymbe/ty-components/button';
import defaultPageSizeOptions from '../../../components/Table/table.utils';
import { Form, FormState } from 'informed';
import moment from 'moment';
import useURLParamsHandler from '../../../hooks/UrlParamsHandler/useURLParamsHandler';
import { processingAbsenceHeaders } from './components/headers/absenceHeaders';
import { toast } from 'react-toastify';
import { useUser } from '../../../apiClient/ApiContext';
import { getPaginatedResponse } from '../../../apiClient/utils';
import { clarificationsQueryId } from './RequestedClarifications.tab';

export const processingAbsencesQueryId = 'admin/procesingAbsences';

type FormApplicationExcuseProps = {
  state: AbsenceState;
  category: {
    value: AbsenceCategory;
    label: string;
  };
};

type AbsenceFormProps = {
  excuses: Record<number, FormApplicationExcuseProps>;
};

export const ProcessingAbsencesTab = () => {
  const [{ allSearchParams, urlParams }] = useURLParamsHandler();
  const sort = allSearchParams.sort ?? { created_at: -1 };
  const search = allSearchParams.search;
  const currentPage = allSearchParams.page ?? 1;
  const pageSize = allSearchParams.pageSize ?? defaultPageSizeOptions[0].value;
  const queryClient = useQueryClient();
  const excuseId = urlParams.get('excuseId');
  const user = useUser();

  const { data: excuses, isLoading: getIsLoading } = useQuery(
    [processingAbsencesQueryId, pageSize, currentPage, sort, search, excuseId],
    () =>
      feathersClient
        .service('application-excuse')
        .find({
          query: {
            $skip: pageSize * (currentPage - 1),
            $limit: pageSize,
            $sort: sort,
            'application_excuse.state': AbsenceState.PROCESSING,
            $eager:
              '[files, application.[shift.company, person.[accountState, personData], excuses]]',
            $joinRelation: 'application.[shift, person.personData]',
            $modify: {
              quickFilter: search?.split(' '),
            },
            ...(excuseId && { 'application_excuse.id': excuseId }),
          },
        })
        .then(getPaginatedResponse)
  );

  const { mutateAsync: patchExcuses, isLoading: patchIsLoading } = useMutation(
    ['PatchExcuses'],
    async (req: AbsenceFormProps): Promise<ApplicationExcuseData[]> => {
      const patchPromises = Object.entries(req.excuses).map(([id, excuse]) => {
        return feathersClient.service('application-excuse').patch(id, {
          state: excuse.state,
          category: excuse.category.value,
          reviewed_at: moment.tz('Europe/Prague').toISOString(),
          reviewed_by: user.id,
        });
      });

      const results = await Promise.all(patchPromises);

      return results;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([processingAbsencesQueryId]);
        queryClient.invalidateQueries([clarificationsQueryId]);
        toast.success(`Absence úspešne zpracovány (${data.length})`);
      },
      onError: (error) => {
        toast.error(`Nastala chyba: ${error}`);
      },
    }
  );

  const onSubmit = (formState: FormState<AbsenceFormProps>) => {
    if (!formState.values.excuses) return;
    patchExcuses(formState.values);
  };

  return (
    <Form<AbsenceFormProps> onSubmit={onSubmit}>
      <TyTable>
        <tbody>
          <TyTableRow className="border-x-0">
            {processingAbsenceHeaders.map((col, idx) => (
              <TyTableSortHeader
                key={idx.toString()}
                field={col.sortable ? col.value : undefined}
                className="border-x-0"
              >
                {col.label}
              </TyTableSortHeader>
            ))}
          </TyTableRow>
          {excuses?.data.map((excuse) => (
            <AbsenceRow excuse={excuse} key={excuse.id} />
          ))}
          <NoDataTableRow
            visible={excuses?.data.length === 0}
            colSpan={processingAbsenceHeaders.length}
          />
        </tbody>
      </TyTable>
      <TyTablePagination rowsCount={excuses?.total} />
      <TyTableFooter className="flex justify-center">
        <PrimaryButton loading={getIsLoading || patchIsLoading}>
          <CheckmarkIcon className="text-buttons-front-secondary-text mr-2" />
          Uložit akce
        </PrimaryButton>
      </TyTableFooter>
    </Form>
  );
};
