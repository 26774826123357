import { ComponentProps } from 'react';
import { Paragraph } from '../text';
import { TyTableCell } from './TableCell';
import { TyTableRow } from './TableRow';

type NoDataTableRowProps = {
  colSpan: number;
  text?: string;
  visible?: boolean;
} & ComponentProps<typeof TyTableRow>;

export const NoDataTableRow = ({
  colSpan,
  children,
  text = 'Žádná data',
  visible = false,
  ...rest
}: NoDataTableRowProps) => {
  if (!visible) return null;

  return (
    <TyTableRow {...rest}>
      <TyTableCell className="py-4" align="center" colSpan={colSpan}>
        <Paragraph variant="body2Sb">
          {text}
        </Paragraph>
        {children}
      </TyTableCell>
    </TyTableRow>
  );
};
